$primaries:(
  "primary-50":#E9F0F6,
  "primary-100":#CCDCEA,
  "primary-200":#99B9D5,
  "primary-300":#6697C1,
  "primary-400":#3374AC,
  "primary-500":#005197,
  "primary-600":#004179,
  "primary-700":#00315B,
  "primary-800":#00203C,
  "primary-900":#00101E,
);
$secondaries:(
  "secondary-100":#CCECE9,
  "secondary-200":#99DAD3,
  "secondary-300":#66C7BD,
  "secondary-400":#33B5A7,
  "secondary-500":#00A291,
  "secondary-600":#008274,
  "secondary-700":#006157,
  "secondary-800":#00413A,
  "secondary-900":#00201D,
);
$successs:(
  "success-100":#D0E4D4,
  "success-200":#A1C9A9,
  "success-500":#157729,
  "success-600":#0F571E,
);
$warnings:(
  "warning-100":#FEE9CC,
  "warning-200":#FED39A,
  "warning-500":#FC9002,
);
$dangers:(
  "danger-100":#F9D4D6,
  "danger-200":#F3A8AD,
  "danger-500":#E22633,
  "danger-600":#C61A26,
);
$grays:(
  "gray-100":#F8F9FA,
  "gray-200":#E9ECEF,
  "gray-300":#DEE2E6,
  "gray-400":#CED4DA,
  "gray-500":#ADB5BD,
  "gray-600":#6C757D,
  "gray-700":#495057,
  "gray-800":#343A40,
  "gray-900":#212529,
);


$theme-colors-shades-list:(
  "primary": $primaries,
  "secondary": $secondaries,
  "success":  $successs,
  "danger": $dangers,
  "warning": $warnings
);

$primary: map-get($primaries, "primary-500");
$secondary: map-get($secondaries, "secondary-500");
$success: map-get($successs, "success-500");
$danger: map-get($dangers, "danger-500");
$warning: map-get($warnings, "warning-500");

